import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'utils';

import { IndentedTextBlock } from 'components/Main/IndentedTextBlock';
import { BetaSignUpButton } from 'components/Beta';

const initialPaddingPercentage = 90;
const endTriggerPercentage = 50;

export function useIsMobile() {
  if (typeof navigator === 'undefined') return false;
  let isMobile = false;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    isMobile = true;
  }
  return isMobile;
}

export const Header = ({ text }) => {
  const pageWrapperRef = useRef();
  const [currentProgress, setCurrentProgress] = useState(0);
  const isMobile = useIsMobile();
  const paddingBottom = isMobile
    ? '0px'
    : `${initialPaddingPercentage * (1 - currentProgress)}%`;

  useEffect(() => {
    const pageAnimation = gsap.to(pageWrapperRef.current, {
      scrollTrigger: {
        start: 'top top',
        end: `+=${endTriggerPercentage}%`,
        scroller: pageWrapperRef.current.closest('.navigation-page'),
        onUpdate: ({ progress }) => {
          setCurrentProgress(progress);
        }
      }
    });

    setCurrentProgress(pageAnimation.scrollTrigger.progress || 0);

    return () => {
      pageAnimation.scrollTrigger.kill();
    };
  }, [pageWrapperRef]);

  const textParts = text.split('<br>');

  return (
    <header
      ref={pageWrapperRef}
      className='header-page bg-whiteShade'
      style={{ paddingBottom }}
    >
      <div className='yellow-circle hidden medium:block' />

      {/* Content */}
      <div className='main-section screen-height vertical-stretch'>
        <section className='section justify-end'>
          {/* Text Block */}
          <IndentedTextBlock
            firstBlock={
              <p className='indented-paragraph text-blackShade-100 max-w-xs medium:max-w-full'>
                {textParts[0]}
              </p>
            }
            secondBlock={
              <p className='indented-paragraph text-blackShade-p60 max-w-3xl'>
                {textParts[1]}
              </p>
            }
            thirdBlock={
              <p className='indented-paragraph text-blackShade-p40'>
                {textParts[2]}
              </p>
            }
            animated={true}
            className='first-fold'
          />
        </section>

        {/* Sign Up Button */}
        <div className='section-cta-wrapper first-fold'>
          <BetaSignUpButton variant='primary' color='pureBlue' />
        </div>
      </div>
    </header>
  );
};
