import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { gsap } from 'utils';

export const IndentedTextBlock = ({
  className = '',
  firstBlock = null,
  secondBlock = null,
  thirdBlock = null,
  animated = false,
  mini = false
}) => {
  const boxRef = useRef();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const indentAnimation = gsap.to(boxRef.current, {
      scrollTrigger: {
        trigger: boxRef.current,
        scroller: boxRef.current.closest('.navigation-page'),
        onToggle: self => {
          setVisible(self.isActive);
        }
      }
    });

    return () => {
      indentAnimation.scrollTrigger.kill();
    };
  }, [boxRef]);

  return (
    <div
      ref={boxRef}
      className={cn(
        'indented-text-block',
        animated ? 'animated' : '',
        mini ? 'mini' : '',
        visible ? 'visible' : '',
        className
      )}
    >
      {firstBlock && <div className='first-block'>{firstBlock}</div>}
      {secondBlock && <div className='second-block'>{secondBlock}</div>}
      {thirdBlock && <div className='third-block'>{thirdBlock}</div>}
    </div>
  );
};
