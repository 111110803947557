import React from 'react';

import { ChapterOdd, ChapterEven } from './sections';

export const OurMission = ({ data }) => {
  const missions = data.missions.nodes;
  let chapters = [];
  for (let i = 0; i < missions.length; i++) {
    if (i % 2 === 0) {
      // Odd Chapter since chapter 1 is i = 0
      chapters.push(<ChapterOdd chapter={missions[i]} />);
    } else {
      // Even Chapter since Chapter 2 is i = 0
      chapters.push(<ChapterEven chapter={missions[i]} />);
    }
  }

  return (
    <main className='main-page our-mission-page'>
      {chapters}
    </main>
  );
};
