import React from 'react';
import { graphql } from 'gatsby';

import { Main } from 'components/Main';

export default function PageWhyDevRev(props) {
  return <Main {...props} />;
}

export const query = graphql`
  query HomepageQuery {
    homepage: strapiHomepage {
      seo {
        title
        description
        keywords
      }
      hero_text
      introducing_heading
      introducing_subheading
      section_3_text
      section_4_text
      delivers_heading
      devires_subheading
      product_heading
      product_subheading
      features_text
      roadmap_heading
      roadmap_subheading
      faq_heading
      faq_subheading
      header {
        subtitle
      }
    }
    introducingTabs: allStrapiHomepageIntroducingTab {
      nodes {
        id
        heading
        subheading
        order
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
      }
    }
    productCards: allStrapiHomepageProductCard {
      nodes {
        id
        letter
        heading
        description
        order
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
      }
    }
    delivers: allStrapiHomepageDeliver {
      nodes {
        id
        heading
        description
        order
        icon {
          localFile {
            svg {
              content
            }
          }
        }
      }
    }

    features: allStrapiHomepageFeature {
      nodes {
        id
        heading
        description
        order
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
        items: homepage_feature_items {
          id
        }
      }
    }

    featureItems: allStrapiHomepageFeatureItem {
      nodes {
        id
        text
        order
        icon {
          localFile {
            svg {
              content
            }
          }
        }
      }
    }

    roadmap: allStrapiHomepageRoadmapItem {
      nodes {
        id
        heading
        description
        status
        order
        icon {
          localFile {
            svg {
              content
            }
          }
        }
      }
    }
    faqs: allStrapiHomepageFaq {
      nodes {
        id
        heading
        text
        order
      }
    }
    productDemo: strapiHomepageProductDemo {
      preview_text
      preview_play_button_text
      heading
      subheading
    }
    missions: allStrapiHomepageMissionChapter(sort: { fields: order }) {
      nodes {
        heading
        subheading
        order
        slug
        title
        subtitle
        wordCount
        intro1
        intro2
        intro3
        content {
          data {
            content
          }
        }
      }
    }
  }
`;
