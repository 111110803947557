import React, { useEffect, useMemo, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { BetaSignUpButton } from 'components/Beta';

import { sortByKey } from 'utils';

const slideTimer = 5000;

const ProductListSmall = ({ items = [] }) => {
  return (
    <div className='product-list small'>
      {items.map(({ id, heading, subheading, image }) => (
        <div key={id}>
          {image.localFile && (
            <div className='product-screenshot mt-smSpacing'>
              <GatsbyImage image={getImage(image.localFile)} alt={heading} />
            </div>
          )}
          <div className='product-content'>
            <div className='product-headline small-paragraph text-blackShade-90'>
              <h4>{heading}</h4>
              <p className='text-blackShade-p60 mb-smSpacing'>{subheading}</p>
            </div>

            <span className='after'></span>
          </div>
        </div>
      ))}
    </div>
  );
};

const ProductListMedium = ({ items = [] }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(null);

  useEffect(() => {
    setCurrentSlideIndex(0);
  }, []);

  return (
    <div className='product-list medium'>
      <Carousel
        animationHandler='fade'
        selectedItem={currentSlideIndex}
        autoPlay={true}
        stopOnHover={false}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        transitionTime={800}
        infiniteLoop={true}
        interval={slideTimer}
        onChange={slideIndex => setCurrentSlideIndex(slideIndex)}
      >
        {items.map(({ id, heading, image }) => {
          return image?.localFile ? (
            <div key={id} className='product-screenshot'>
              <GatsbyImage image={getImage(image.localFile)} alt={heading} />
            </div>
          ) : null;
        })}
      </Carousel>
      <div className='product-content-list'>
        {items.map(({ id, heading, subheading }, i) => (
          <button
            key={id}
            className='product-content'
            onClick={() => setCurrentSlideIndex(i)}
          >
            <span className='product-headline text-blackShade-90'>
              <h4 className='block'>{heading}</h4>
              <p className='text-blackShade-p60'>{subheading}</p>
            </span>

            <span
              className={`after ${currentSlideIndex === i ? 'active' : ''}`}
              style={{
                transitionDuration: `${
                  currentSlideIndex === i ? slideTimer : 0
                }ms`
              }}
            />
            <span className='after-hover'></span>
          </button>
        ))}
      </div>
    </div>
  );
};

export const Introduction = ({ heading, subheading, items = [] }) => {
  const { medium } = useBreakpoint();

  const _items = useMemo(() => sortByKey(items), [items]);

  return (
    <section className='main-section vertical-stretch bg-greyShade-5'>
      <section className='section'>
        {/* Title */}
        <div className='main-title'>
          <h2 className='title text-blackShade-90'>{heading}</h2>
          <p className='sub-title text-blackShade-p60'>{subheading}</p>
        </div>

        {/* Product List */}
        {medium ? (
          <ProductListMedium items={_items} />
        ) : (
          <ProductListSmall items={_items} />
        )}
      </section>

      {/* Sign Up Button */}
      <div className='section-cta-wrapper'>
        <BetaSignUpButton variant='primary' color='pureBlue' />
      </div>
    </section>
  );
};
