import React, { useMemo } from 'react';

import { BetaSignUpButton } from 'components/Beta';

import { sortByKey } from 'utils';

export const Benefits = ({ heading, subheading, items = [] }) => {
  const _items = useMemo(() => sortByKey(items), [items]);

  return (
    <section className='main-section vertical-stretch bg-blackShade-100'>
      <section className='section'>
        {/* Title */}
        <div className='main-title'>
          <h2 className='title text-whiteShade'>{heading}</h2>
          <p className='sub-title text-whiteShade-p70'>{subheading}</p>
        </div>

        {/* Benefits */}
        <ul className='benefit-list'>
          {_items.map(({ id, icon, heading, description }) => (
            <li key={id} className='benefit'>
              {icon?.localFile?.svg?.content && (
                <span
                  className='benefit-icon'
                  dangerouslySetInnerHTML={{
                    __html: icon.localFile.svg.content
                  }}
                />
              )}
              <span className='benefit-content'>
                <h4 className='benefit-text text-whiteShade'>{heading}</h4>
                <p className='benefit-text text-whiteShade-p70 max-w-xl'>
                  {description}
                </p>
              </span>
            </li>
          ))}
        </ul>
      </section>

      {/* Sign Up Button */}
      <div className='section-cta-wrapper'>
        <BetaSignUpButton variant='primary' color='whiteShade-p70' />
      </div>
    </section>
  );
};
