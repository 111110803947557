import React from 'react';

import { IndentedTextBlock } from 'components/Main/IndentedTextBlock';

export const ProblemStatement = ({ text = '' }) => {
  const textParts = text.split('<br>');

  return (
    <section className='main-section problem-statement-page bg-blackShade-100'>
      {/* Text Block */}
      <IndentedTextBlock
        firstBlock={
          <p className='indented-paragraph text-whiteShade'>{textParts[0]}</p>
        }
        secondBlock={
          <p className='indented-paragraph text-whiteShade-p70'>
            {textParts[1]}
          </p>
        }
        thirdBlock={
          <p className='indented-paragraph text-whiteShade-p40'>
            {textParts[2]}
          </p>
        }
        mini={true}
      />
    </section>
  );
};
