import React, { useMemo } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { sortByKey } from 'utils';

const WalkThroughCard = ({ heading, description, letter, image }) => {
  return (
    <div className='feature-card'>
      <div className='feature-text'>
        <div className='feature-title'>
          <p className='feature-paragraph'>{letter}</p>
          <div className='flex flex-col'>
            <p className='feature-paragraph'>{heading}</p>
            <p className='feature-description'>
              <span className='space-y-xxsSpacing'>
                <span className='block max-w-xl'>{description}</span>
              </span>
            </p>
          </div>
        </div>
        <p className='feature-description'>
          <span className='space-y-xxsSpacing'>
            <span className='block max-w-xl'>{description}</span>
          </span>
        </p>
      </div>
      <div className='feature-screen'>
        <div className='feature-screen-inner'>
          <GatsbyImage
            className={`feature-screen-img ${letter.toLowerCase()}`}
            image={getImage(image.localFile)}
            alt={heading}
          />
        </div>
      </div>
    </div>
  );
};

export const WalkThrough = ({ heading, subheading, items = [] }) => {
  const _items = useMemo(() => sortByKey(items), [items]);

  return (
    <section className='main-section screen-height vertical-stretch walk-through-page bg-whiteShade'>
      <section className='section'>
        {/* Title */}
        <div className='main-title'>
          <h2 className='title text-blackShade-90'>{heading}</h2>
          <p className='sub-title text-blackShade-p60'>{subheading}</p>
        </div>

        {/* Feature Cards */}
        <div className='feature-cards'>
          {_items.map(item => (
            <WalkThroughCard {...item} key={item.id} />
          ))}
        </div>
      </section>
    </section>
  );
};
