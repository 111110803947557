import React from 'react';

import { IndentedTextBlock } from 'components/Main/IndentedTextBlock';
import { Markdown } from 'components/_shared/Markdown';

export const ChapterEven = ({ chapter }) => {
  const {
    heading,
    subheading,
    title,
    subtitle,
    slug,
    wordCount,
    intro1,
    intro2,
    intro3,
    content
  } = chapter;
  return (
    <React.Fragment>
      <header className='main-section section-headline-page screen-height vertical-stretch bg-greyShade-10'>
        <section className='section'>
          {/* Title */}
          <div className='main-title'>
            <h2 id={slug} className='title text-blackShade-90'>
              {heading}
            </h2>
            <p className='sub-title text-blackShade-p60'>{subheading}</p>
          </div>
        </section>

        <section className='section justify-end'>
          {/* Text Block */}
          <IndentedTextBlock
            firstBlock={
              <p className='indented-paragraph text-blackShade-100'>{title}</p>
            }
            secondBlock={
              <p className='indented-paragraph text-blackShade-p60'>
                {subtitle}
              </p>
            }
          />
        </section>
        {
          /* Word Count */ wordCount && (
            <div className='flex section-cta-wrapper first-fold'>
              <p className='word-count text-whiteShade-p70'>
                <span>{wordCount}</span>
              </p>
            </div>
          )
        }
      </header>
      <section className='main-section section-page-2 screen-height vertical-stretch bg-whiteShade'>
        <section className='section'>
          {/* Text Block */}
          <IndentedTextBlock
            firstBlock={
              <p className='indented-paragraph text-blackShade-90'>{intro1}</p>
            }
            secondBlock={
              <p className='indented-paragraph text-blackShade-p60'>{intro2}</p>
            }
            thirdBlock={
              <p className='indented-paragraph text-blackShade-p40'>{intro3}</p>
            }
          />
        </section>

        <section className='section justify-end'>
          <div className='narrow-section'>
            {/* Copy Blocks */}
            <div className='copy-blocks'>
              <Markdown markdown={content.data.content} wrapper={false} variant={false} />
            </div>
          </div>
        </section>
      </section>
    </React.Fragment>
  );
};
