import React, { useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { sortByKey } from 'utils';

const FaqAnswer = ({ answer, isOpen }) => {
  const { height, ref } = useResizeDetector();

  return (
    <div
      className={`faq-answer-wrapper ${isOpen ? 'open' : ''}`}
      style={{ height: isOpen ? height : 0 }}
    >
      <p ref={ref} className='faq-answer max-w-xl'>
        {answer}
      </p>
    </div>
  );
};

export const Faq = ({ heading, subheading, items = [] }) => {
  const [openIndexes, setOpenIndexes] = useState([0]);

  const _items = useMemo(() => sortByKey(items), [items]);

  return (
    <section className='main-section faq-page bg-pureBlue'>
      {/* Title */}
      <div className='main-title'>
        <h2 className='title text-whiteShade'>{heading}</h2>
        <p className='sub-title text-whiteShade-p70'>{subheading}</p>
      </div>

      {/* FAQ List */}
      <ol className='faq-list'>
        {_items.map(({ id, heading, text }, i) => (
          <li key={id} className='faq-item'>
            <div className='faq-content'>
              <button
                className='faq-question'
                onClick={() => {
                  setOpenIndexes(currentOpenIndexes => {
                    if (openIndexes.includes(i)) {
                      const currentIndex = openIndexes.indexOf(i);
                      return [
                        ...currentOpenIndexes.slice(0, currentIndex),
                        ...currentOpenIndexes.slice(currentIndex + 1)
                      ];
                    } else {
                      return [...currentOpenIndexes, i];
                    }
                  });
                }}
              >
                {heading}
              </button>
              <FaqAnswer answer={text} isOpen={openIndexes.includes(i)} />
            </div>
          </li>
        ))}
      </ol>
    </section>
  );
};
