import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { IndentedTextBlock } from 'components/Main/IndentedTextBlock';
import { BetaSignUpButton } from 'components/Beta';

import { sortByKey } from 'utils';

export const Screens = ({ text = '', items = [] }) => {
  const textParts = text.split('<br>');

  return (
    <section className='main-section screen-height vertical-stretch screens-page bg-greyShade-5'>
      <section className='section'>
        {/* Text Block */}
        <IndentedTextBlock
          className='medium:pt-0'
          firstBlock={
            <p className='indented-paragraph text-blackShade-100'>
              {textParts[0]}
            </p>
          }
          secondBlock={
            <p className='indented-paragraph text-blackShade-p60'>
              {textParts[1]}
            </p>
          }
        />

        {/* Screen List */}
        <div className='screen-list'>
          {items.map(({ id, heading, description, items, image }) => (
            <div key={id} className='screen-slide'>
              <div className='screen-screenshot'>
                <GatsbyImage image={getImage(image.localFile)} alt={heading} />
              </div>
              <div className='screen-content'>
                <div className='screen-title'>
                  <h4 className='screen-paragraph text-blackShade-90'>
                    {heading}
                  </h4>
                  <p className='screen-paragraph text-blackShade-p60'>
                    {description}
                  </p>
                </div>
                <ul className='screen-features'>
                  {sortByKey(items).map(({ id, icon, text }) => (
                    <li key={id} className='screen-feature'>
                      {icon?.localFile?.svg?.content && (
                        <span
                          className='screen-feature-icon text-blackShade-90'
                          dangerouslySetInnerHTML={{
                            __html: icon.localFile.svg.content
                          }}
                        />
                      )}

                      <h5 className='screen-feature-title text-blackShade-90'>
                        {text}
                      </h5>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Sign Up Button */}
      <div className='section-cta-wrapper'>
        <BetaSignUpButton variant='primary' color='whiteShade-p70' />
      </div>
    </section>
  );
};
