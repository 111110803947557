import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import slugify from 'slugify';
import cn from 'classnames';

import { IconClose } from 'assets';

import { SEO as Seo } from 'components/SEO';
import { HomePage } from './HomePage';
import { ProductDemo } from './ProductDemo';
import { OurMission } from './OurMission';
import { BetaSignUpButton } from 'components/Beta';

import { headerEvents } from '_events';

import './Main.styles.scss';

const pages = [
  {
    id: 'index',
    name: 'Home',
    Component: HomePage,
    textColor: 'blackShade-90',
    bgColor: 'whiteShade',
    className: 'navigation-section-home'
  },
  {
    id: 'demo',
    name: 'Product Demo',
    Component: ProductDemo,
    textColor: 'whiteShade',
    bgColor: 'blackShade-90',
    className: 'navigation-section-product'
  },
  {
    id: 'mission',
    name: 'Our Mission',
    Component: OurMission,
    textColor: 'whiteShade',
    bgColor: 'blackShade-100',
    className: 'navigation-section-mission'
  }
].map(page => ({ ...page, slug: slugify(page.name, { lower: true }) }));

const FIRST_SLUG = pages[0].slug;
const INITIAL_SLUG = FIRST_SLUG;
const PARAM_TAB_NAME = 'tab';

export const Main = ({ data }) => {
  const {
    homepage: { seo }
  } = data;

  const { search: params, pathname } = useLocation();

  const [activeSlug, setActiveSlug] = useState(INITIAL_SLUG);
  const [isHeaderMenuOpen, setIsHeaderMenuOpen] = useState(false);

  const seoTitle =
    activeSlug === FIRST_SLUG
      ? seo.title
      : `${seo.title} | ${pages.find(({ slug }) => activeSlug === slug)?.name}`;

  const handleTabSwitch = (slug, i) => {
    const nextSlug =
      activeSlug === slug ? pages[i - 1]?.slug || FIRST_SLUG : slug;

    if (nextSlug === FIRST_SLUG) {
      return navigate(pathname);
    }

    navigate(`${pathname}?${PARAM_TAB_NAME}=${nextSlug}`);
  };

  useEffect(() => {
    const paramSlug = new URLSearchParams(params).get(PARAM_TAB_NAME);
    const isValidParamSlug = pages.some(({ slug }) => slug === paramSlug);
    if (!isValidParamSlug) navigate(pathname);
    setActiveSlug(isValidParamSlug ? paramSlug : INITIAL_SLUG);
  }, [params, pathname]);

  useEffect(() => headerEvents.onToggleMenu(setIsHeaderMenuOpen), []);

  return (
    <main className={cn('main', { isHeaderMenuOpen })}>
      <Seo {...seo} title={seoTitle} />

      {pages.map(
        ({ name, Component, textColor, bgColor, className, slug }, i) => {
          const isActiveSlug = activeSlug === slug;

          const canRenderCloseButton = pages.some((page, i) => {
            return i !== 0 && page.slug === slug;
          });

          return (
            <section
              key={slug}
              className={cn(
                'navigation-section',
                { open: isActiveSlug },
                className
              )}
            >
              {/* Tab */}
              <button
                className={`navigation-tab text-${textColor} bg-${bgColor}`}
                onClick={() => handleTabSwitch(slug, i)}
              >
                <span>{name}</span>
              </button>

              {/* Page */}
              <div className='navigation-page-wrapper'>
                {/* Closing X for Our Mission & Product Demo */}
                {canRenderCloseButton && (
                  <button
                    className={`navigation-close text-${textColor}`}
                    onClick={() => handleTabSwitch(slug, i)}
                  >
                    <IconClose />
                  </button>
                )}

                {/* Sticky CTA for Home Page  */}
                {slug === FIRST_SLUG && (
                  <BetaSignUpButton
                    className='sticky-cta'
                    variant='primary'
                    color='pureBlue'
                  />
                )}

                <div className='navigation-page'>
                  <Component open={isActiveSlug} data={data} />
                </div>
              </div>
            </section>
          );
        }
      )}
    </main>
  );
};
