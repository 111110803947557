import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';

import { IndentedTextBlock } from 'components/Main/IndentedTextBlock';

import { IconPause, IconTriangleRight } from 'assets';
import Player from '@vimeo/player';

function toTimeString(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

const videoSections = [
  { id: 'section-1', title: 'Customer Conversation', duration: 25, startAt: 0 },
  { id: 'section-2', title: 'Work Management', duration: 47, startAt: 25 },
  {
    id: 'section-3',
    title: 'Product System of Record',
    duration: 61,
    startAt: 72
  },
  { id: 'section-4', title: 'Social', duration: 56, startAt: 133 }
];

export const ProductDemo = ({ data, open }) => {
  const { productDemo: fields } = data;
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const [hasOverLayer, setHasOverLayer] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playerState, setPlayerState] = useState('paused');

  const previewTextParts = fields.preview_text.split('<br>');

  useEffect(() => {
    if (!videoRef.current) return;
    const videoPlayer = (playerRef.current = new Player(videoRef.current));
    const progressHandler = ev => {
      const activeSectionIndex = videoSections.findIndex(
        section =>
          section.startAt <= ev.seconds &&
          ev.seconds < section.startAt + section.duration
      );
      setActiveIndex(activeSectionIndex);
      setCurrentTime(ev.seconds);
    };
    const pauseHandler = () => setPlayerState('paused');
    const endedHandler = () => setPlayerState('ended');
    const playHandler = () => setPlayerState('play');

    videoPlayer.on('timeupdate', progressHandler);
    videoPlayer.on('pause', pauseHandler);
    videoPlayer.on('ended', endedHandler);
    videoPlayer.on('play', playHandler);
    return () => {
      playerRef.current = null;
      videoPlayer.off('timeupdate', progressHandler);
      videoPlayer.off('pause', pauseHandler);
      videoPlayer.off('ended', endedHandler);
      videoPlayer.off('play', playHandler);
      videoPlayer.unload();
    };
  }, []);

  useEffect(() => {
    if (!open && playerRef.current) {
      playerRef.current.getPaused().then(function (paused) {
        if (!paused) {
          playerRef.current.pause();
        }
      });
    } else if (!hasOverLayer) {
      Promise.all([
        playerRef.current.getPaused(),
        playerRef.current.getEnded()
      ]).then(([paused, ended]) => {
        if (paused && !ended) {
          playerRef.current.play();
        }
      });
    }
  }, [open, hasOverLayer]);

  const overLayer = (
    <main className='main-section product-demo-page-over-layer screen-height vertical-stretch'>
      <section className='section section-video-demo-over-layer'>
        <section className='section flex-grow justify-end'>
          {/* Text Block */}
          <IndentedTextBlock
            firstBlock={
              <h2 className='indented-paragraph text-whiteShade'>
                {previewTextParts[0]}
              </h2>
            }
            secondBlock={
              <p className='indented-paragraph text-whiteShade-p70'>
                {previewTextParts[1]}
              </p>
            }
            className='first-fold'
          />
        </section>

        {/* Watch Button */}
        <div className='flex section-cta-wrapper first-fold'>
          <button
            className='watch-now-button whitespace-nowrap'
            onClick={() => {
              setHasOverLayer(false);
              if (playerRef.current) {
                playerRef.current.play();
              }
            }}
          >
            <span>{fields.preview_play_button_text}</span>
            <IconTriangleRight />
          </button>
        </div>
      </section>
    </main>
  );

  const handleVideoContainerClick = () => {
    if (playerRef.current) {
      playerRef.current.getPaused().then(paused => {
        if (paused) {
          playerRef.current.play();
        } else {
          playerRef.current.pause();
        }
      });
    }
  };
  return (
    <main
      className={cn(
        'main-page product-demo-page',
        hasOverLayer ? 'has-layer' : ''
      )}
    >
      <section className='section section-video-demo screen-height bg-blackShade-90'>
        <section className='main-section flex-grow'>
          <section className='flex flex-shrink'>
            {/* Title */}
            <div className='main-title'>
              <h2 className='title text-whiteShade'>{fields.heading}</h2>
              <p className='sub-title text-whiteShade-p70'>
                {fields.subheading}
              </p>
            </div>
          </section>

          <section className='video-container'>
            <div
              className='absolute medium:inset-0 left-0 right-0 top-0 bottom-[180px] cursor-pointer outline-none'
              role='button'
              aria-label='Play control'
              tabIndex={0}
              onClick={handleVideoContainerClick}
              onKeyDown={handleVideoContainerClick}
            ></div>
            {/* Video */}
            <iframe
              ref={videoRef}
              className='video-frame'
              title='Product Demo Video'
              src='https://player.vimeo.com/video/700961699?controls=false&loop=true&background=1&autoplay=0'
              frameBorder='0'
              allow='autoplay'
              webkitallowfullscreen='true'
              mozallowfullscreen='true'
              allowFullScreen
            />
          </section>
        </section>

        {/* Video Sections */}
        <div className='video-sections'>
          {videoSections.map(({ id, title, duration, startAt }, i) => (
            <button
              key={id}
              className={cn(
                'video-section',
                activeIndex === i && playerState !== 'ended' ? 'active' : ''
              )}
              style={{
                backgroundSize: `${Math.max(
                  Math.min(((currentTime - startAt) * 100) / duration, 100),
                  0
                )}% 100%`
              }}
              onClick={() => {
                if (activeIndex === i) {
                  if (playerState === 'play') {
                    playerRef.current.pause();
                  } else if (playerState === 'paused') {
                    playerRef.current.play();
                  } else if (playerState === 'ended') {
                    playerRef.current.seekTo(startAt);
                    playerRef.current.play();
                  }
                  return;
                } else {
                  setActiveIndex(i);
                  if (playerRef.current) {
                    playerRef.current.setCurrentTime(startAt);
                    playerRef.current.play();
                  }
                }
              }}
            >
              <span className='video-section-wrapper whitespace-nowrap'>
                <span className='video-section-icon'>
                  {playerState === 'paused' ? (
                    <IconTriangleRight />
                  ) : (
                    <IconPause />
                  )}
                </span>
                <span className='video-section-title'>{title}</span>
              </span>
              <span className='video-section-time'>
                {toTimeString(Math.round(startAt))}
              </span>
            </button>
          ))}
        </div>
      </section>

      {overLayer}
    </main>
  );
};
