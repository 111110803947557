import React, { useCallback, useMemo } from 'react';
import { sortByKey } from 'utils';
import { Helmet } from 'react-helmet';

import {
  Benefits,
  Faq,
  FeatureRoadmap,
  Header,
  Introduction,
  ProblemStatement,
  Screens,
  SolutionStatement,
  WalkThrough
} from './sections';

import { Header as MainHeader } from 'components/Header';
import { Footer } from 'components/Footer';

export const HomePage = ({ data }) => {
  const { homepage: page, features, featureItems } = data;

  const _features = useMemo(() => {
    return sortByKey(features.nodes).map(feature => ({
      ...feature,
      items: sortByKey(
        feature.items.map(({ id }) => {
          return featureItems.nodes.find(i => i.id === id);
        })
      )
    }));
  }, [features, featureItems]);

  const getHeaderScroller = useCallback(header => {
    return header?.closest('.navigation-page');
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name='ahrefs-site-verification'
          content='14318bb3f1c21f44486a231a5957265832c3d2fbc57fe5bb6d6730eee8965d8a'
        />
      </Helmet>
      <MainHeader
        className='main-page-header'
        subtitle={page.header.subtitle}
        signUpButton={{ color: 'pureBlue' }}
        menuButton={{ color: 'black' }}
        scroller={getHeaderScroller}
      />

      <main className='main-page home-page'>
        <Header text={page.hero_text} />

        <Introduction
          heading={page.introducing_heading}
          subheading={page.introducing_subheading}
          items={data.introducingTabs.nodes}
        />

        <ProblemStatement text={page.section_3_text} />

        <SolutionStatement text={page.section_4_text} />

        <Benefits
          heading={page.delivers_heading}
          subheading={page.devires_subheading}
          items={data.delivers.nodes}
        />

        <WalkThrough
          heading={page.product_heading}
          subheading={page.product_subheading}
          items={data.productCards.nodes}
        />

        <Screens text={page.features_text} items={_features} />

        <FeatureRoadmap
          heading={page.roadmap_heading}
          subheading={page.roadmap_subheading}
          items={data.roadmap.nodes}
        />

        <Faq
          heading={page.faq_heading}
          subheading={page.faq_subheading}
          items={data.faqs.nodes}
        />

        <Footer />
      </main>
    </>
  );
};
