const colors = require('tailwindcss/colors');

module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}'],
  darkMode: 'media',
  mode: 'jit',
  theme: {
    extend: {
      zIndex: {
        '100': '100',
      },
      screens: {
        lg: '1136px',
        'md-max': { max: '768px' },
        'lg-max': { max: '1136px' },
        // [Start] 2022 HomePage
        medium: '600px',
        'medium-max': { max: '600px' },
        large: '1025px'
        // [End] 2022 HomePage
      },
      maxWidth: {
        devrev: '1440px'
      },
      borderWidth: {
        '05': '0.5px'
      },
      height: {
        '05': '0.5px'
      },
      margin: {
        17: '4.5rem'
      },
      spacing: {
        26: '6.5rem',
        // [Start] 2022 HomePage
        xxsSpacing: '5px',
        xsSpacing: '10px',
        smSpacing: '15px',
        mdSpacing: '30px',
        lgSpacing: '40px',
        xlSpacing: '60px'
        // [End] 2022 HomePage
      },
      opacity: { '03': '0.03' },
      colors: {
        indigo: {
          50: '#EDF0FE',
          100: '#E4E9FE',
          200: '#CAD3FD',
          300: '#A6B5FC',
          400: '#7A90FB',
          500: '#617BFA',
          600: '#4D6BF9',
          700: '#4159CB',
          800: '#35479C',
          900: '#29356E'
        },
        teal: {
          ...colors.teal,
          100: '#E9FCF7'
        },
        devrev: {
          white: '#FDFDFD',
          black: {
            90: '#1A1A1A'
          },
          gray: {
            5: '#F2F2F2',
            10: '#E6E6E6'
          }
        },
        aqua: {
          100: '#E8F7FD',
          800: '#0A5571'
        },
        // [Start] 2022 HomePage
        blackShade: {
          90: '#1A1A1A',
          100: '#000000',
          p20: 'rgba(00, 00, 00, 0.2)',
          p40: 'rgba(00, 00, 00, 0.4)',
          p60: 'rgba(00, 00, 00, 0.6)'
        },
        greyShade: {
          5: '#F2F2F2',
          10: '#E6E6E6',
          20: '#CCCCCC'
        },
        whiteShade: {
          DEFAULT: '#FDFDFD',
          p20: 'rgba(255, 255, 255, 0.2)',
          p40: 'rgba(255, 255, 255, 0.4)',
          p70: 'rgba(255, 255, 255, 0.7)'
        },
        pureBlue: {
          DEFAULT: '#2122FF',
          hover: '#0708DA'
        },
        brightYellow: {
          DEFAULT: '#FFD300'
        },
        honeyYellow: {
          DEFAULT: '#FFAB00'
        },
        emeraldGreen: {
          DEFAULT: '#008C1E'
        },

        // [End] 2022 HomePage

        base: {
          900: '#202020'
        }
      },
      transitionDelay: {
        0: '0ms'
      },
      fontFamily: {
        biotif: ['Biotif', 'ui-sans-serif', 'system-ui', '-apple-system'],
        sfPro: ['SF Pro', 'SF Pro Text', '-apple-system', 'system-ui'],
        sfMono: [
          'SF Mono',
          'Menlo',
          'Monaco',
          'Consolas',
          'Liberation Mono',
          'Courier New',
          'ui-monospace',
          'monospace'
        ]
      },
      fontSize: {
        'xs-m': '0.8125rem',
        'sm-s': '0.875rem',
        'base-xs': '1.0625rem'
      },
      lineHeight: {
        increased: '122%'
      }
    }
  },

  plugins: [require('@tailwindcss/forms')]
};
