import React, { useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config.js';

import { BetaSignUpButton } from 'components/Beta';

const fullConfig = resolveConfig(tailwindConfig);

const countdownDate = Date.now() + 8640020000;
const clockColor = fullConfig.theme.colors.blackShade[100];

export const ScarcitySignUp = () => {
  const [clockSeconds, setClockSeconds] = useState(0);
  const degs = clockSeconds * 6;

  return (
    <section className='main-section scarcity-page screen-height vertical-stretch bg-pureBlue'>
      <section className='section scarcity-section'>
        {/* Title */}
        <div className='main-title'>
          <h2 className='title text-whiteShade'>Sign up for Beta Now</h2>
          <p className='sub-title text-whiteShade-p70'>
            Exclusivity/Scarcity Driver
          </p>
        </div>

        {/* Clock */}
        <div className='scarcity-circle-container'>
          <div
            className='scarcity-circle'
            style={{
              backgroundImage: `conic-gradient(from 0deg, transparent 0deg, transparent ${degs}deg, ${clockColor} ${degs}deg, ${clockColor} 360deg)`
            }}
          ></div>
        </div>
      </section>

      <section className='section gap-smSpacing medium:gap-mdSpacing large:gap-largeSpacing'>
        {/* Countdown */}
        <Countdown
          zeroPadTime={2}
          onTick={({ seconds }) =>
            setClockSeconds(seconds === 0 ? 0 : 60 - seconds)
          }
          date={countdownDate}
          renderer={({ days, hours, minutes, seconds }) => {
            return (
              <div className='scarcity-countdown'>
                {days}d:{zeroPad(hours)}h:{zeroPad(minutes)}m:{zeroPad(seconds)}
                s
              </div>
            );
          }}
        />

        {/* Sign Up Button */}
        <div className='section-cta-wrapper'>
          <BetaSignUpButton variant='primary' color='whiteShade-p70' />
        </div>
      </section>
    </section>
  );
};
