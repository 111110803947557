import React from 'react';

import { IndentedTextBlock } from 'components/Main/IndentedTextBlock';

export const SolutionStatement = ({ text = '' }) => {
  const textParts = text.split('<br>');

  return (
    <section className='main-section vertical-stretch solution-statement-page bg-brightYellow'>
      <section className='section'>
        {/* Text Block */}
        <IndentedTextBlock
          firstBlock={
            <p className='indented-paragraph text-blackShade-90 max-w-3xl'>
              {textParts[0]}
            </p>
          }
          secondBlock={
            <p className='indented-paragraph text-blackShade-p60 max-w-3xl'>
              {textParts[1]}
            </p>
          }
          thirdBlock={
            <p className='indented-paragraph text-blackShade-p40'>
              {textParts[2]}
            </p>
          }
        />
      </section>

      <img className='pill-image' src='/assets/images/pills.svg' alt='DevRev' />
    </section>
  );
};
//
